<script>
import * as echarts from 'echarts'
import loading from '@/utils/loading'
import { mapGetters } from 'vuex'
import { ROLES } from '@/constants/constants'
import { i18n } from '@/main'
import salesAssistantService from '@/services/salesAssistant'
export default {
  props: ['formData', 'from', 'rolesInfo', 'empData', 'filterParams'],
  data() {
    return {
      myChart1: null,
      myChart2: null,
      chartData: [],
      online: '',
      chartDataRight: [],
      chartDataLeft: [],
      FOLLOW_TARGET_COUNT: 0,
      PASSENGER_TARGET_COUNT: 0,
      roleCode: '',
      i18n
    }
  },
  computed: {
    ...mapGetters(['userInfo']),
    // 是否展示客流量
    showKl() {
      // const { roleLogos=[] } = this.userInfo
      // return roleLogos.some((item) => { return [ROLES.SHOWOWNER].includes(item) })
      return !this.roleCode || this.roleCode == ROLES.SHOWOWNER
    }
  },
  mounted() {
    // this.initTranfer(this.formData)
    window.addEventListener('resize', () => {
      this.myChart1 && this.myChart1.resize()
      this.myChart2 && this.myChart2.resize()
    })
  },
  methods: {
    chartsInit() {
      const { finished, } = this.chartDataLeft[0] // 目标完成量
      const { target } = this.chartDataRight[0] // 目标量
      if (!this.myChart1) {
        const el = document.getElementById('chart-container-left')
        const myChart = echarts.init(el)
        this.myChart1 = myChart
        const options = {
          title: {
            text: this.$t('实际'),
            left: 'right',
            top: 'top',
            textStyle: { fontSize: 14, color: '#0D171A', fontWeight: 500 },
          },
          color: '#eed484',
          series: [
            {
              sort: 'none',
              type: 'funnel',
              width: '100%',
              height: '90%',
              left: '0',
              top: '10%',
              maxSize:
                finished > target
                  ? '100%'
                  : `${((finished / target) * 100).toFixed(2)}%`,
              funnelAlign: 'right',
              gap: 1,
              label: {
                show: false,
                position: 'insideRight',
                fontSize: 12,
                formatter: function(params) {
                  const { finishRate, code, name, targetRate } = params.data
                  console.log(params)

                  if (code == 'LEAD_TARGET') {
                    return `{a|${name}量}{b|}{c|${params.value}}`
                  }
                  return `{a|${name}量}{b|}{c|${params.value}(${finishRate})}`
                },
                rich: {
                  a: {
                    borderColor: '#ccc',
                    width: 40,
                    backgroundColor: 'transparent',
                    textBorderColor: 'none',
                  },
                  b: {
                    height: 0,
                    width: 80,
                    borderWidth: 0.5,
                    borderColor: '#ccc',
                    borderType: 'dashed',
                  },
                  c: {
                    color: '#111213',
                    backgroundColor: 'transparent',
                    textBorderColor: 'none',
                    width: 40,
                  },
                },
              },
              data: this.chartDataLeft,
            },
          ],
        }
        this.myChart1.setOption(options)
      } else {
        this.myChart1.setOption({
          series: {
            maxSize:
                finished > target
                  ? '100%'
                  : `${((finished / target) * 100).toFixed(2)}%`,
            data: this.chartDataLeft,
          },
        })
        this.myChart1.resize()
      }
      if (!this.myChart2) {
        const el = document.getElementById('chart-container-right')
        const myChart = echarts.init(el)
        this.myChart2 = myChart
        const options = {
          color: '#eed484',
          title: {
            text: this.$t('目标'),
            left: 'left',
            top: 'top',
            textStyle: { fontSize: 14, color: '#0D171A', fontWeight: 500 },
          },
          series: [
            {
              type: 'funnel',
              width: '100%',
              height: '90%',
              left: 0,
              top: '10%',
              funnelAlign: 'left',
              gap: 1,
              maxSize:
                finished < target
                  ? '100%'
                  : `${((target / finished) * 100).toFixed(2)}%`,
              label: {
                show: false,
                position: 'insideLeft',
                fontSize: 12,
                formatter: function(params) {
                  const { code, name, targetRate, difference } = params.data
                  if (code == 'LEAD_TARGET') {
                    return `{a|${
                      params.value
                    }}{b|}{c|${name}量差值\n${difference || 0}}`
                  }
                  return `{a|${
                    params.value
                  }(${targetRate})}{b|}{c|${name}量差值\n${difference ||
                    0}}{d|}`
                },
                rich: {
                  a: {
                    height: 20,
                    color: '#111213',
                    backgroundColor: 'transparent',
                    textBorderColor: 'none',
                    verticalAlign: 'middle',
                  },
                  b: {
                    height: 0,
                    width: 10,
                    borderWidth: 0.5,
                    borderColor: '#ccc',
                    borderType: 'dashed',
                  },
                  c: {
                    height: 40,
                    lineHeight: 12,
                    width: 65,
                    color: '#ccc',
                    align: 'center',
                    backgroundColor: 'transparent',
                    textBorderColor: 'none',
                  },
                },
              },
              zlevel: 1,
              data: this.chartDataRight,
            },
          ],
        }
        this.myChart2.setOption(options)
      } else {
        this.myChart2.setOption({
          series: {
            maxSize:
                finished < target
                  ? '100%'
                  : `${((target / finished) * 100).toFixed(2)}%`,
            data: this.chartDataRight,
          },
        })
        this.myChart2.resize()
      }
    },
    async initTranfer(obj) {
      try {
        // 判断是销售助手引用 且获取当前角色
        this.roleCode = obj.roleCode
        loading.showLoading()
        const { dealers } = this.$store.getters.userInfo
        // const res = await statisticsKpi({
        //   statisticsTime: obj.statisticsTime,
        //   empIds: obj.empIds,
        //   channelOneId: obj.channelOneId,
        //   roleCode: obj.roleCode,
        //   dealerId: dealers[0].id,
        //   queryType: obj.queryType
        // })
        const res = await salesAssistantService.workOverview(obj)
        loading.hideLoading()
        const chartDataRight = []
        let arr = res.targetStats
        arr = res.targetStats.filter(({ code }) => { return !['FOLLOW_TARGET','PASSENGER_TARGET'].includes(code) })
        this.FOLLOW_TARGET_COUNT = res.targetStats.find(({ code }) => { return code === 'FOLLOW_TARGET' })?.finished || 0
        this.PASSENGER_TARGET_COUNT = res.targetStats.find(({ code }) => { return code === 'PASSENGER_TARGET' })?.finished || 0
        this.chartDataLeft = arr.map((item) => {
          chartDataRight.push({ ...item, value: item.target })
          return {
            ...item,
            value: item.finished,
          }
        })
        this.chartDataRight = chartDataRight
        this.chartsInit()
      } catch (error) {
        console.log(error)
        loading.hideLoading()
      }
    },
    // 跳转
    gotoPath(item) {
      const { code } = item
      const { statisticsTime={}, empIds=[], roleCode, channelOneId='' } = this.formData
      const { parentCode='', childCode, dealerProvinceCode='', dealerCityCode='', dealerIds } = this.filterParams || {}
      const { start, end } = statisticsTime
      if (code === 'LEAD_TARGET'){
        // 跳转线索明细
        this.$router.push({
          path: '/clue-list',
          query: {
            validTimeStart: start,
            validTimeEnd: end,
            followUserIds: empIds,
            channelOneId,
            roleCode,
            validStatus: 1,
            parentCode,
            childCode,
            dealerProvinceCode,
            dealerCityCode,
            dealerIds
          }
        })
        return
      }
      // if (this.$route.name === 'salesAssistantPortalHome') return
      if (['APPOINTMENT_TARGET','DRIVE_TARGET'].includes(code)){
        // 跳转预约
        this.$router.push({
          path: '/schedulingDrive',
          query: {
            followUserIds: empIds.join(','),
            type: code,
            roleCode,
            start,
            end,
            parentCode,
            childCode,
            dealerProvinceCode,
            dealerCityCode,
            dealerIds
          }
        })
        return
      }
      if (['ORDER_TARGET','DELIVERY_TARGET'].includes(code)){
        const query = {
          followUserIds: empIds,
          roleCode
        }
        if (code==='ORDER_TARGET'){
          // query.orderCreatedTimeStart = start
          // query.orderCreatedTimeEnd = end
          // query.contractTimeStart = start
          // query.contractTimeEnd = end
          query.lockTimeStart = start
          query.lockTimeEnd = end
        }
        if (code==='DELIVERY_TARGET'){
          query.deliveryTimeStart = start
          query.deliveryTimeEnd = end
          query.orderStatuses = 'ORDER_COMPLETED'
        }
        // 跳转订单
        this.$router.push({
          path: '/order-list',
          query: {
            ...query,
            parentCode,
            childCode,
            dealerProvinceCode,
            dealerCityCode,
            dealerIds
          },
        })
        return
      }
      if (code === 'FOLLOW_TARGET') {
        this.$router.push({
          path: '/taskManage',
          query: {
            taskStatus: '1025002',
            execTimeStart: start,
            execTimeEnd: end,
            roleCode,
            parentCode,
            childCode,
            dealerProvinceCode,
            dealerCityCode,
            dealerIds
          }
        })
        return
      }
    },
  },
}
</script>
<template>
  <div class="grid-container">
    <div class="overView">
      <div  @click="gotoPath({code: 'FOLLOW_TARGET'})">{{ $t('跟进量') }}：{{ FOLLOW_TARGET_COUNT }}</div>
      <div v-if="showKl">{{ $t('客流量') }}：{{ PASSENGER_TARGET_COUNT }}</div>
    </div>
    <div class="nav-bar">
      <div class="data-laebel">
        <div v-if="!i18n.locale.includes('zh')" class="Differ">Differ</div>
        <div v-for="item in chartDataLeft" :key="item.code" class="item">
          <div class="left" @click="gotoPath(item)">
            <span class="name">{{ item.name }}</span>
            <span class="line"></span>
            <span v-if="item.code == 'LEAD_TARGET'"
              >{{ item.finished || 0 }}
            </span>
            <span v-else>
              {{ `${item.finished || 0}(${item.finishTransRate || 0})` }}
            </span>
          </div>
          <div class="right">
            <span v-if="item.code == 'LEAD_TARGET'">{{
              item.target || 0
            }}</span>
            <span v-else>{{
              `${item.target || 0}(${item.targetRate || 0})`
            }}</span>
            <span class="line"></span>
            <span class="name">
              <p v-if="i18n.locale.includes('zh')">{{ item.name + '差值' }}</p>
              <p style="font-size: 13px;">{{ `(${item.difference || 0})` }}</p>
            </span>
          </div>
        </div>
      </div>
      <div
        id="chart-container-left"
        ref="chartInstance"
        style="margin-left:68px"
        class="grid-item charts"
      ></div>
      <div
        id="chart-container-right"
        ref="chartInstance"
        style="margin-right:68px"
        class="grid-item charts"
      ></div>
    </div>
  </div>
</template>
<style lang="less" scoped>
.grid-container {
  display: flex;
  // padding: 5px 10px 10px;
  flex-direction: column;
  .overView{
    display: flex;
    justify-content: space-between;
    margin: 12px 0;
    height: 16px;
    line-height: 16px;
    color: #B9921A;
    font-size: 14px;
  }
  .charts {
    height: 200px;
    flex: 1;
  }
  .grid-item {
    overflow: hidden;
    flex-shrink: 0;
  }
  .tur {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    color: #0d171a73;
    padding-top: 80px;
    padding-bottom: 10px;
    font-size: 12px;
    > div {
      white-space: nowrap;
    }
  }
  .nav-bar {
    position: relative;
    flex: 1;
    display: flex;
    overflow: hidden;
    min-height: 200px;
    .data-laebel {
      font-size: 12px;
      z-index: 100;
      position: absolute;
      height: 90%;
      width: 100%;
      bottom: 0;
      left: 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .Differ {
        position: absolute;
        top: -10%;
        left: 0;
        right: 0;
        font-size: 14px; 
        color: #0D171A;
        font-weight: 500;
        height: 10%;
        display: flex;
        justify-content: flex-end;
      }
      .item {
        display: flex;
        flex: 1;
        .left,
        .right {
          padding: 0 4px;
          width: 50%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .line {
            flex: 1;
            margin: 0 5px;
            border-top: 1px dashed #ccc;
          }
          p {
            line-height: 1;
          }
        }
      }
    }
    .nav {
      display: flex;
      justify-content: space-around;
      margin-bottom: 10px;
      > span:not(.line) {
        padding: 4px 6px;
        line-height: 1;
        background: #f6ecc9;
        border-radius: 3px;
        font-size: 12px;
      }
      .line {
        border-left: 1px dashed #cccccc;
      }
    }
  }
}
</style>
